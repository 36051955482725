

.App {
  background-color: #282c34;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(255, 255, 255, 0.769);
}

.text-box {
  font-size: x-large;
  background-color: rgba(211, 211, 211, 0.379);
  width: 300px;
  border: 6px solid green;
  border-radius: 5%;
  padding: 50px;
  margin: 20px;
}

.button-box {
  font-size: small;
  background-color: rgba(211, 211, 211, 0.529);
  width: 150px;
  border: 4px solid blue; 
  border-radius: 12%;
  padding: 10px;
  margin: 5px;
  
}

footer {
  font-size: small; 
}

